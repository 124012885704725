class Video {
  playVideo(video) {
    const iframe = video.querySelector('.js-video-iframe');
    iframe.setAttribute('src', iframe.getAttribute('data-src'));

    if (video.dataset.inline) {
      video.parentElement.classList.add('is-open');
    } else {
      video.classList.add('is-open');
    }
  }

  closeVideo(video) {
    const iframe = video.querySelector('.js-video-iframe');

    if (video.dataset.inline) {
      video.parentElement.classList.remove('is-open');
    } else {
      video.classList.remove('is-open');
    }

    setTimeout(() => {
      iframe.setAttribute('src', '');
    }, 300);
  }

  init() {
    const playButtons = document.querySelectorAll('.js-video-play');


    playButtons.forEach((play) => {
      play.addEventListener('click', (e) => {
        e.preventDefault();

        const { videoId } = play.dataset;

        if (videoId) {
          const video = document.querySelector(`.js-video-${play.dataset.videoId}`);

          this.playVideo(video);

          document.addEventListener('keyup', e => onEsc(e, video));
        } else {
          console.error('Vimeo video id is missing!');
        }
      });
    });

    const closeButtons = document.querySelectorAll('.js-video-close');

    closeButtons.forEach((close) => {
      close.addEventListener('click', (e) => {
        e.preventDefault();

        const video = document.querySelector(`.js-video-${close.dataset.videoId}`);

        this.closeVideo(video);

        document.removeEventListener('keyup', onEsc);
      });
    });

    const onEsc = (e, video) => {
      if (e.key === 'Escape') {
        this.closeVideo(video);
      }
    };
  }
}

export default Video;
