class Stamp {
  constructor() {
    this.stamp = document.querySelector('.js-stamp');
    this.degree = 0;
    this.lastScrollY = 0;
  }

  bindings() {
    const { stamp } = this;

    window.addEventListener('scroll', () => {
      if (this.lastScrollY < window.scrollY) {
        this.degree += 1.5;
      } else {
        this.degree -= 1.5;
      }

      stamp.style.transform = `rotate(${this.degree}deg)`;

      this.lastScrollY = window.scrollY;
    });
  }

  init() {
    if (this.stamp) {
      this.bindings();
    }
  }
}

export default Stamp;
