class Footer {
  constructor() {
    this.headings = document.querySelectorAll('.js-footer-heading');
  }

  bindings() {
    const { headings } = this;

    headings.forEach((heading) => {
      heading.addEventListener('mouseenter', () => {
        heading.parentElement.classList.add('is-hover');
      });

      heading.addEventListener('mouseleave', () => {
        heading.parentElement.classList.remove('is-hover');
      });
    })
  }

  init() {
    if (this.headings) {
      this.bindings();
    }
  }
}

export default Footer;
