import lottie from 'lottie-web';

class Loader {
  constructor() {
    this.loader = document.querySelector('.js-loader');
    this.loaderWrapper = document.querySelector('.js-loader-wrapper');
    this.isLoaderSeen = window.sessionStorage && window.sessionStorage.getItem('isLoaderSeen') !== null;

    this.animation = lottie.loadAnimation({
      container: this.loader,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/app/themes/the-west-quarter/assets/animations/logo.json'
    });
  }

  triggerLoadedEvent() {
    const event = new Event('siteLoaded');
    document.dispatchEvent(event);
  }

  init() {
    const {
      loaderWrapper,
      animation,
      isLoaderSeen
    } = this;

    if (!loaderWrapper) {
      return;
    }

    if (!isLoaderSeen) {
      animation.addEventListener('complete', () => {
        loaderWrapper.classList.add('is-hidden');
        this.triggerLoadedEvent();
      });

      animation.play();

      if (window.sessionStorage) {
        window.sessionStorage.setItem('isLoaderSeen', true);
      }
    } else {
      loaderWrapper.parentNode.removeChild(loaderWrapper);
      this.triggerLoadedEvent();
    }
  }
}

export default Loader;
