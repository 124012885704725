class Header {
  constructor() {
    this.header = document.querySelector('.js-header');
    this.navToggles = document.querySelectorAll('.js-nav-toggle');
    this.logo = document.querySelector('.l-header__logo');
    this.body = document.querySelector('body');
    this.isOpen = false;
  }

  bindings() {
    const {
      body,
      header,
      navToggles,
      logo
    } = this;

    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 200) {
        logo.classList.add('is-sticky');
      } else {
        logo.classList.remove('is-sticky');
      }
    });

    document.addEventListener('keydown', (e) => {
      if (this.isOpen && e.keyCode === 27) {
        body.classList.remove('is-frozen');
        header.classList.remove('is-open');

        this.isOpen = false;
      }
    });

    navToggles.forEach((navToggle) => {
      navToggle.addEventListener('click', (e) => {
        e.preventDefault();

        this.isOpen = this.isOpen ? false : true;

        body.classList.toggle('is-frozen');
        header.classList.toggle('is-open');
      });
    })
  }

  init() {
    if (this.header) {
      this.bindings();
    }
  }
}

export default Header;
