import 'unfetch/polyfill';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/native-loading/ls.native-loading';

import Loader from './components/loader';
import Video from './components/video';
import Header from './components/header';
import Stamp from './components/stamp';
import Footer from './components/footer';
import Carousel from './components/carousel';
import Reveal from './components/reveal';

class App {
  constructor() {
    this.video = new Video();
    this.reveal = new Reveal();
    this.header = new Header();
    this.loader = new Loader();
    this.stamp = new Stamp();
    this.footer = new Footer();
    this.carouselOverflowText = new Carousel('.js-carousel-w-overflow-text');
    // this.imageTextCarousel = new Carousel('.js-image-w-text-carousel');
  }

  init() {
    this.video.init();
    this.reveal.init();
    this.header.init();
    this.stamp.init();
    this.footer.init();
    this.loader.init();
    this.carouselOverflowText.init();
    // this.imageTextCarousel.init();
    this.triggerAnimations();
  }

  triggerAnimations() {
    window.addEventListener('load', () => {
      document.body.classList.remove('preload');
    });
  }
}

const app = new App();
app.init();
