import Swiper from 'swiper';

class Carousel {
  constructor(scope, options = {}) {
    this.scope = scope;
    this.swiper = null;

    const defaultOptions = {
      loop: true,
      speed: 600,
      pagination: {
        el: '.js-image-w-text-carousel-pagination',
        type: 'bullets',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    this.options = { ...defaultOptions, ...options };
  }



  init() {
    const { scope, options } = this;

    this.swiper = new Swiper(scope, options);
  }
}

export default Carousel;
